import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

/**
 * Utils
 */
const objectsToUpdate = [];

// Track mouse position in normalized device coordinates (-1 to +1) for both axes
const mouse = new THREE.Vector2();
window.addEventListener('mousemove', (event) => {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
});

/**
 * GLTF Loader
 */
const gltfLoader = new GLTFLoader();

function constructCubes(gltf, amount) {
  const model = gltf.scene; // Assuming the model is the first child
  model.scale.set(0.35, 0.35, 0.35);
  const box = new THREE.Box3().setFromObject(model);
  const size = box.getSize(new THREE.Vector3());

  for (let index = 0; index < amount; index++) {
    const modelClone = model.clone();
    const position = {
      x: (Math.random() - 0.5) * 50,
      y: (Math.random() - 0.5) * 50,
      z: (Math.random() - 0.5) * 50,
    };
    createBoxFromModel(modelClone, position, size);
  }
}

gltfLoader.load('/models/cube_outside_2.gltf', (gltf) => {
  constructCubes(gltf, 12);
});

gltfLoader.load('/models/cube_relief_1.gltf', (gltf) => {
  constructCubes(gltf, 10);
});

gltfLoader.load('/models/cube_yellow.gltf', (gltf) => {
  constructCubes(gltf, 22);
});

function createBoxFromModel(model, position, size) {
  const halfExtents = new CANNON.Vec3(
    size.x * 0.6, // Slighly smaller than the model to generate a little gap between the objects
    size.y * 0.6, // Slighly smaller than the model to generate a little gap between the objects
    size.z * 0.6 // Slighly smaller than the model to generate a little gap between the objects
  );
  model.position.set(position.x, position.y, position.z);
  scene.add(model);

  // Create a physics body for the model
  const shape = new CANNON.Box(halfExtents);
  const body = new CANNON.Body({
    mass: 1,
    position: new CANNON.Vec3(position.x, position.y, position.z),
    shape,
    material: defaultMaterial,
    linearDamping: 0.9, // High linear damping
    angularDamping: 0.9, // High angular damping
  });
  world.addBody(body);

  objectsToUpdate.push({ mesh: model, body });
}

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

// World
const world = new CANNON.World();

world.broadphase = new CANNON.SAPBroadphase(world);
world.allowSleep = true;
world.solver.iterations = 10; // Increase solver iterations for more accuracy
// Gravity is Vec3 (not Vector 3). It is the same principal as Vector 3 but for Cannon.js
world.gravity.set(0, 0, 0); // xyz - in which direction is gravity supposed to be applied | -9.82 is the gravity constant on earth

// * Simple way for one default material - often suffices for a project
const defaultMaterial = new CANNON.Material('default');

// How the physics bodies are supposed to behave
const defaultContactMaterial = new CANNON.ContactMaterial(
  defaultMaterial,
  defaultMaterial,
  {
    friction: 0.5, // Default is 0.3
    restitution: 0.1, // Bounce | Default is 0.3
  }
);
world.addContactMaterial(defaultContactMaterial);
world.defaultContactMaterial = defaultContactMaterial;

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.75);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 2.25);
directionalLight.castShadow = true;
directionalLight.shadow.mapSize.set(1024, 1024);
directionalLight.shadow.camera.far = 15;
directionalLight.shadow.camera.left = -7;
directionalLight.shadow.camera.top = 7;
directionalLight.shadow.camera.right = 7;
directionalLight.shadow.camera.bottom = -7;
directionalLight.position.set(3, 3, 3);
scene.add(directionalLight);

// const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
// directionalLight2.castShadow = true;
// directionalLight2.shadow.mapSize.set(1024, 1024);
// directionalLight2.shadow.camera.far = 15;
// directionalLight2.shadow.camera.left = -7;
// directionalLight2.shadow.camera.top = 7;
// directionalLight2.shadow.camera.right = 7;
// directionalLight2.shadow.camera.bottom = -7;
// directionalLight2.position.set(-3, 3, 3);
// scene.add(directionalLight2);

/**
 * Sizes
 */
const sizes = {
  width: document.querySelector('.hero').clientWidth,
  height: document.querySelector('.hero').clientHeight,
};

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = document.querySelector('.hero').clientWidth;
  sizes.height = document.querySelector('.hero').clientHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  50,
  sizes.width / sizes.height,
  0.1,
  100
);

camera.position.set(0, 0, 8);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
});
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock(); // This clock is used to keep track of the elapsed time since the application started.
let oldElapsedTime = 0;

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  let deltaTime = elapsedTime - oldElapsedTime; // Ensures that deltaTime is the same regardless of device
  oldElapsedTime = elapsedTime;

  world.step(1 / 60, deltaTime, 3);

  // Instead of addressing just one object, we address each object stored in the array
  // Update positions and rotations of all objects based on physics
  for (const object of objectsToUpdate) {
    object.mesh.position.copy(object.body.position);
    object.mesh.quaternion.copy(object.body.quaternion);

    // Calculate the direction and magnitude of the force to apply towards the center
    const forceDirection = new CANNON.Vec3(
      -object.body.position.x * 4,
      -object.body.position.y * 4,
      -object.body.position.z * 4
    );
    object.body.applyForce(forceDirection, object.body.position);

    objectsToUpdate.forEach((object) => {
      object.mesh.position.copy(object.body.position);
      object.mesh.quaternion.copy(object.body.quaternion);

      // Check mouse proximity
      const distSquared = object.mesh.position.distanceToSquared(
        new THREE.Vector3(mouse.x * 5, mouse.y * 5, object.mesh.position.z) // Smaller scaling factor
      );
      if (distSquared < 1) {
        // Smaller proximity threshold
        const forceDirection = object.mesh.position
          .clone()
          .sub(
            new THREE.Vector3(
              mouse.x * 5, // Adjusted to match scaling factor
              mouse.y * 5,
              object.mesh.position.z
            )
          )
          .normalize()
          .multiplyScalar(0.25); // Reduced force magnitude
        object.body.applyForce(
          new CANNON.Vec3(forceDirection.x, forceDirection.y, forceDirection.z),
          object.body.position
        );
      }

      // Wake the body if it's sleeping
      if (object.body.sleepState === CANNON.Body.SLEEPING) {
        object.body.wakeUp();
      }
    });
  }

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
